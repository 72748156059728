html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  background: transparent;
  border: none;
  font-size: 100%;
  font: inherit;
  line-height: 1;
  margin: 0;
  padding: 0;
  position: relative;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
img,
main {
  display: block;
}

/* Avoid image out of element. */
img {
  max-width: 100%;
}

html {
  /* Prevent adjustments of font size after orientation changes in iOS. */
  -webkit-text-size-adjust: 100%;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  /* Add the correct box sizing in Firefox. */
  box-sizing: content-box;
  height: 0;
  /* Show the overflow in Edge and IE. */
  overflow: visible;
}

a {
  /* Remove the gray background on active links in IE 10. */
  background-color: transparent;
}

abbr[title] {
  /* Remove the bottom border in Chrome 57- */
  border-bottom: none;
  /* Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari. */
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  /* Add the correct font weight in Chrome, Edge, and Safari. */
  font-weight: bolder;
}

button,
input {
  /* Show the overflow in IE & Edge. */
  overflow: visible;
  /* Remove outline. */
  outline: none;
  /* Vertical Align */
  vertical-align: middle;
}

button,
select {
  /* Remove the inheritance of text transform in Edge, Firefox, and IE. */
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /* Correct the inability to style clickable types in iOS and Safari. */
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  /* Remove the inner border and padding in Firefox. */
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: none;
}

textarea {
  /* Remove the default vertical scrollbar in IE 10+. */
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  /* Add the correct box sizing in IE 10. */
  box-sizing: border-box;
  /* Remove the padding in IE 10. */
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  /* Correct the cursor style of increment and decrement buttons in Chrome. */
  height: auto;
}

[type="search"] {
  /* Correct the odd appearance in Chrome and Safari. */
  -webkit-appearance: textfield;
  /* Remove outline. */
  outline: none;
}

[type="search"]::-webkit-search-decoration {
  /* Remove the inner padding in Chrome and Safari on macOS. */
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  /* Correct the inability to style clickable types in iOS and Safari. */
  -webkit-appearance: button;
  /* Change font properties to `inherit` in Safari. */
  font: inherit;
}

summary {
  /* Add the correct display in all browsers. */
  display: list-item;
}

template {
  /* Add the correct display in IE 10+. */
  display: none;
}

[hidden] {
  /* Add the correct display in IE 10. */
  display: none;
}
